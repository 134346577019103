$body: #071314!important;
$headings-font-weight: 300 !important;
$primary: #1b46c2;
$light: #fafafa;

$entity-font-family: "'Exo 2'", sans-serif !important;
$entity-header-font-family: "EquinoxRegular", sans-serif !important;
$entity-exo-font-family: "'Exo 2'", sans-serif !important;
$entity-font-size: 14px;

$green-300: #123737;
$litegreen-400: #83FF8C;
$green-100: #206666;
$green-200: #184D4D;
$green-100-transparent: rgb(65 183 183 / 27%);

$entity-green: $litegreen-400;
$entity-green-dark: #82ff8b45;
$entity-red: #ca6f6f;
$entity-red-dark: #ca6f6f8e;
$entity-red-medium: #ff6767;
$premium-blue: #00ffeb;
$blue: #8dc7ff;
$medium-blue: #4dc2f9;
$white: #fff !default;
$maiar-color: #F76D42;
$light-gray: #c9c9c9;
$gray: #708787;
$gray-second: #707070;
$medium-gray: #454444;
$dark-gray-second: #363636;
$dark-gray: #333333;
$metal: #96c3c3;
$metal-dark: #789397;
$elrond-color: $white;
$background-color: #061313;
$page-block-background-color: $green-300;
$form-select-indicator-color: #fff;
$dark-green: #172525;
$green-opaque: #3b696b;
$entity-border: #3f5e5d;
$entity-text-grey: #a3b1b4;
$entity-bg-dark-green: #184d4d;

.entity-theme {
    --entity-green: #{$entity-green};
    --entity-green-dark: #{$entity-green-dark};
    --entity-red: #{$entity-red};
    --entity-red-dark: #{$entity-red-dark};
    --entity-red-medium: #{$entity-red-medium};
    --entity-font-family: #{$entity-font-family};
    --entity-header-font-family: #{$entity-header-font-family};
    --entity-exo-font-family: #{$entity-exo-font-family};
    --entity-font-size: #{$entity-font-size};
    --premium-blue: #{$premium-blue};
    --medium-blue: #{$medium-blue};
    --blue: #{$blue};
    --maiar-color: #{$maiar-color};
    --elrond-color: #{$elrond-color};
    --light-gray: #{$light-gray};
    --entity-gray: #{$gray};
    --entity-gray-second: #{$gray-second};
    --medium-gray: #{$medium-gray};
    --dark-gray-second: #{$dark-gray-second};
    --dark-gray: #{$dark-gray};
    --dark-green: #{$dark-green};
    --green-opaque: #{$green-opaque};
    --metal: #{$metal};
    --metal-dark: #{$metal-dark};
    --white: #{$white};

    --page-block-title-color: #51E2DF;
    --page-block-background-color: #{$page-block-background-color};
    --background-color: #{$background-color};
    --table-header-bg-color: #132a2e;
    --card-bg-dark: #132a2e;
    --field-bg-color: #0D2626;
    --field-placeholder-color: #{$light};
    --field-border-color: #{$litegreen-400};
    --disabled-field-bg-color: #081c1c;
    --disabled-field-color: #{$gray};
    --table-col-bg-color: #{$green-100-transparent};
    --green-100: #{$green-100};
    --green-200: #{$green-200};

    --icons-bg-grey: #708787;
    --inactive-menu-item-color: #{$gray};
    --entity-border-green: #{$entity-border};
    --entity-text-grey: #{$entity-text-grey};
    --entity-bg-darg-green: #{$entity-bg-dark-green};
}

.light {
    --theme-color: #A80000;
    --theme-page-text: #111;
    --primary-text: #000000;
    --secondary-text: #000;
    --card-bg: #fff;
    --icon-bg: #{$dark-gray};
    --bg-header: #{$dark-gray};
    --bg-footer: #{$light-gray};
    --icon-footer: #{$light-gray};
    --default-text: #000;
    --primary-bg-btn: #86FE94;
    --primary-tx-btn: #000;
    --link-hover-color: #86FE94;
}

.dark {
    --theme-color: #0000A8;
    --background-color: #{$background-color};
    --theme-page-text: #FFF;
    --primary-text: #fff;
    --secondary-text: #d5d5d5;
    --card-bg: #184d4d80;
    --card-text: #{$white};
    --icon-bg: "transparent";
    --bg-header: #000000;
    --bg-footer: #000;
    --icon-footer: #{$gray-second};
    --default-text: #83FF8C;
    --primary-bg-btn: none;
    --primary-tx-btn: #{$medium-blue};
    --link-hover-color: #86FE94;
}
