@import "./variables.scss";
@import "./date-picker.scss";

.sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-center {
    display: flex;
    align-items: center;
}

body {
    background-color: #071314 !important;
}

/* Bootstrap overrides
-------------------------------------------------- */
.w-80 {
    width: 80%;
}

.w-94 {
    width: 94%;
}

.w-100 {
    width: 100%;
}

.pl-xs {
    padding-left: 0.25rem;
}

.pr-xs {
    padding-right: 0.25rem;
}

iframe {
    width: 100%;
}

.d-contents {
    display: contents;
}

.form-text {
    color: #b9b9b9 !important;
}

.text-green {
    color: $entity-green;

    :hover {
        color: $white;
    }
}

.text-red {
    color: $entity-red;
}

.text-blue {
    color: $blue;
}

.text-metal {
    color: $metal;
}

.text-metal-dark {
    color: $metal-dark;
}

.pointer-cursor {
    cursor: pointer;
}

.ml-0-5 {
    margin-left: 0.5rem;
}

.ml-1 {
    margin-left: 1rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.mr-1 {
    margin-right: 1rem;
}

.ml-auto {
    margin-left: auto;
}

.required:after {
    content: " *";
    color: #bf4d4d;
}

.form-switch .form-check-input {
    width: 3.4rem !important;
    height: 1.7rem;
}

.form-check-input {
    width: 1rem !important;
    height: 1rem !important;
}

.form-control:disabled,
.form-control[readonly] {
    color: #999999;
}

.form-select:disabled,
.form-select[readonly] {
    color: #999999;
}

.provider-select > div {
    > div > div {
        > svg {
            color: #353a40;
        }
    }
}

.modal-backdrop {
    --bs-backdrop-bg: #414141 !important;
}

.modal-body {
    .form-control {
        background-color: #313f43 !important;
    }
}

.breadcrumb {
    background-color: unset !important;
}

.nav-link,
a.nav-link,
.nav-item a.nav-link {
    color: white !important;

    &:hover {
        color: $entity-green !important;
    }
}

.nav-link,
a.nav-link.reverse,
.nav-item a.nav-link.reverse {
    color: $entity-green !important;

    &:hover {
        color: white !important;
    }
}

.offcanvas-body .nav-link {
    color: #fff !important;

    &:hover {
        color: $entity-green !important;
    }
}

h4,
.h4 {
    font-size: 1.2rem !important;
}

.white {
    color: white;
}

.row > * {
    padding-right: calc(var(--bs-gutter-x) * 0.8) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.8) !important;
}

.Logo-nav {
    img {
        display: block;
        margin: 0 auto;
        max-height: 70px;
    }
}

.offcanvas-header .btn-close {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 260px) {
    .maintenance {
        .logo-maintenance {
            max-width: 180px;
        }

        h1 {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    .Logo-nav {
        width: 150px;
    }

    .homepage-description {
        font-size: 1.9rem;
        line-height: 2.5rem;
        font-weight: 300;
        text-transform: uppercase;
    }

    .homepage-page-title {
        color: #fff;
    }

    .strategy-table {
        overflow-x: scroll;
        overflow-y: hidden;

        .card {
            padding-right: 1rem;
        }
    }
    .strategy-table {
        overflow-x: scroll;
        overflow-y: hidden;

        .card {
            padding-right: 1rem;
        }
    }

    .strategy-table {
        .card {
            padding-right: 1rem;
        }
    }
}

// Medium devices (tablets, 768px and down)
@media (max-width: 768px) {
    .entityTable {
        .minw {
            min-width: 230px;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    iframe {
        min-height: 415px;
    }

    .ul-txwallets .form-check {
        min-width: 260px;
    }

    .faq-page.card {
        padding: 8rem 12rem !important;
    }

    .wrapper-slider {
        padding-left: 35px;
        padding-right: 10px;
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1140px !important;
    }
}

@media (max-width: 1400px) {
    .strategy-table {
        overflow-x: scroll;
        overflow-y: hidden;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .entityCard-feature .card-text-second {
        max-width: 50%;
        margin-right: 2rem;
    }

    .maintenance {
        .logo-maintenance {
            max-width: 280px;
        }

        h1 {
            font-size: 4rem;
            font-weight: bold;
        }
    }

    .Logo-nav {
        display: block;
        margin: 0 auto;

        img {
            height: 70px;
            display: block;
            margin: 0 auto;
        }
    }

    .homepage-page-title {
        color: #000;
    }

    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1400px !important;
    }
}

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap
@import "./../../../node_modules/bootstrap/scss/bootstrap.scss";

@import url("https://fonts.googleapis.com/css2?family=Krub:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo%202:wght@200;300;400;500;600&display=swap");
@import "./../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "./../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "./../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "./../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "./../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "./../../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

/* Base
-------------------------------------------------- */

@font-face {
    font-family: "EquinoxRegular";
    src: url("./../fonts/Equinox_Regular.woff") format("woff");
}

@font-face {
    font-family: 'Antonio Variable';
    src: url('./../fonts/Antonio-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "EquinoxBold";
    src: url("./../fonts/Equinox_Bold.woff") format("woff");
    font-weight: bold;
}

html {
    min-height: 100%;
    font-size: $entity-font-size;
    font-family: $entity-font-family;
}

a {
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}

p {
    line-height: 2rem;
}

h2 {
    color: var(--primary-text);
    font-size: 1.4rem;
}

.page-state_title,
.page-state_description {
    color: var(--primary-text);
}

main {
    margin-bottom: 1.5rem;
}

.ml-min {
    margin-left: 0.5rem;
}

.mr-min {
    margin-right: 0.5rem;
}

.Logo-nav {
    display: block;
}

.staking-provider-info {
    background-color: #314042;
    padding: 0.3rem;
    border-radius: 0.5rem;
    border: 1px solid #515f60;
}

.cursor-pointer {
    cursor: pointer !important;
}

.text-small {
    font-size: 0.8rem;
}

.bg-naventity {
    background: transparent;
}

.opacity-6 {
    opacity: 0.6;
}

.dapp-icon {
    padding: 5px;
    border-radius: 50%;
    background-color: $light;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.icon-medium {
        width: 80px;
        height: 80px;
    }
}

.pink-text {
    color: #ff76df;
}

.page-description {
    font-size: 16px;
    color: var(--secondary-text);
    line-height: 2.3rem;
    font-weight: 200;
}

.delimiter {
    color: #ccc;
}

.form-error {
    color: #fe7777 !important;
    margin-bottom: 0;
}

.form-control {
    color: #fff;
    background-color: #193b3b;
    border-color: #101010;
    padding: 0.7rem 0.75rem;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--field-placeholder-color);
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--field-placeholder-color);
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--field-placeholder-color);
    }
}

.form-label {
    margin-bottom: 0.6rem;
    font-size: 0.9rem;
    font-weight: 200;
}

.text-green {
    color: $entity-green;
}

.text-yellow {
    color: #f2de07;
}

.text-red {
    color: #ff6767;
}

.link-green a {
    color: $entity-green !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 0 !important;
}

.mt-25 {
    margin-top: 2.5rem;
}

.mt-30 {
    margin-top: 3rem;
}

.mt-44 {
    margin-top: 4.4rem;
}

.pt-25 {
    padding-top: 2.5rem;
}

.opacity-5 {
    opacity: 0.5;
}

.modal-content {
    background-color: #7c7c7c;
    border-radius: 1rem;
}

.text-right {
    text-align: right;
}

/* Contact Page
---------------------------------------- */
.wrapper-picture {
    height: 14rem;
    line-height: 5rem;
    width: 14rem;
    border-radius: 5.5rem;
    position: relative;
    margin: 0 auto;
    display: block;

    .picture {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 50%;
            box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.33);
            -webkit-box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.33);
            -moz-box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.33);
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            border-radius: 50%;
        }
    }
}

.people-contact {
    color: $gray;
    margin-top: 1.4rem;

    svg {
        font-size: 2rem;
    }

    a {
        color: $gray;

        &:hover {
            color: $white;
        }
    }

    .person-name {
        color: #fff;
    }
}

.wrapper-picture.first {
    height: 16rem;
    line-height: 5rem;
    width: 16rem;
    border-radius: 8.5rem;
    border: 10px solid $gray-second;
    background: #a1a1a1;

    .picture {
        text-align: center;
        line-height: 13rem;
        font-size: 6rem;
        color: #9cffa2;
    }
}

.contact-footer-text {
    font-size: 0.9rem;
    color: #ddd;

    a {
        color: $entity-green;
    }
}

/* Navbar
-------------------------------------------------- */
.barsMenu {
    font-size: 1.8rem;
    color: #cecece;
}

header {
    nav {
        z-index: 0;
        padding: 1rem 0 !important;
    }
}

.entity-logo {
    width: 85px;
    height: 25px;
}

.navbar-dark {
    .navbar-toggler {
        border: none;
    }

    .navbar-nav .nav-link {
        color: #fff;
    }
}

#offcanvasNavbar {
    background: #7c7c7c;

    .navbar-nav .nav-link {
        font-weight: 200;
        font-size: 1.15rem;
    }

    background-color: rgba(255, 255, 255, 0.13);
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

/* Cards
-------------------------------------------------- */

.nav-tabs .nav-link.active {
    border: 1px solid #3c3c3c !important;
    color: #fff;
    background: #3c3c3c !important;
}

.entityCard {
    height: 100%;
}

.entityCard,
.card {
    // TODO: delete when redesign is completed (use ECard component instead)
    background: var(--card-bg);
    border-radius: 10px;
    padding: 1rem 1.5rem;
    letter-spacing: 0;

    .card-body {
        color: #fff;
    }

    font-family: var(--entity-font-family);

    .card-title.h5 {
        font-size: 2rem;
        font-weight: 400 !important;
        color: var(--primary-text);
        line-height: 2.571rem;
    }

    .card-text {
        color: var(--card-text);
        font-size: 1rem;
        position: relative;
        line-height: 2.429rem;
    }

    .text-info-card {
        color: var(--default-text);
        font-size: 0.93rem;
        font-weight: 300;
    }
}

.entityCard {
    overflow: hidden;
    padding-top: 2rem;

    .card-body {
        padding-top: 20px;
    }
}

.entityCard-feature {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    box-shadow: 5px 5px 15px #00000029;
    background: linear-gradient(90deg, $entity-green 0%, #5db063 29%, #4f6e51 100%);
    border: none;

    &:after {
        content: "";
        background-image: url("../img/tree.png");
        background-repeat: no-repeat;
        background-size: 198px;
        background-position: top;
        background-position-y: -4rem;
        width: 206px;
        height: 115px;
        left: 130px;
        top: 0;
        opacity: 0.15;
        z-index: 0;
        position: absolute;
    }

    .card-title {
        font-size: 1.6rem;
        font-weight: 400 !important;
        flex-shrink: 0;
        color: #333;
        text-transform: uppercase;
    }

    .card-text {
        color: #fff;

        a {
            color: $entity-green;
            text-decoration: none;
        }
    }
}

.entityCard-premium {
    background: linear-gradient(90deg, #23918a 0%, #315f5c 100%);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    box-shadow: 5px 5px 15px #00000029;
    border: none;

    a {
        color: #84fc8c !important;
    }

    &:after {
        content: "";
        background-image: url("../img/tree.png");
        background-repeat: no-repeat;
        background-size: 198px;
        background-position: top;
        background-position-y: -4rem;
        width: 206px;
        height: 115px;
        left: 130px;
        bottom: 0;
        opacity: 0.15;
        z-index: 0;
        position: absolute;
    }

    .card-title {
        font-size: 1.6rem;
        font-weight: 400 !important;
        flex-shrink: 0;
        color: #00ffeb;
        text-transform: uppercase;
    }

    .card-text {
        color: #fff;

        a {
            color: #00ffeb;
            text-decoration: none;
        }
    }
}

.wallect-connect-login_card {
    box-shadow: none;
    border: none !important;
}

.ledger-login_wrapper {
    box-shadow: none;

    .ledger-login_tableContent {
        color: #fff;
    }

    .ledger-login_arrowButton {
        color: #86fc95;

        &:hover {
            color: #fff;
        }
    }
}

.linechart {
    max-height: 450px;
    min-height: 200px;
}

.min-w-350 {
    min-width: 350px !important;
}

.lh-0 {
    line-height: 0 !important;
}

.switch.btn {
    min-width: 4.7rem;
}

/* Breadcrumb
-------------------------------------------------- */

.breadcrumb-item + .breadcrumb-item::before {
    color: $gray;
    content: var(--bs-breadcrumb-divider, ">") !important;
}

ol.breadcrumb {
    font-size: 0.9rem;
}

li.breadcrumb-item.active {
    color: var(--primary-text);
}

li.breadcrumb-item {
    a {
        color: $entity-green !important;
        text-decoration: none;
    }
}

.e-mb-4 {
    margin-bottom: 2rem !important;
}

/* Homepage
-------------------------------------------------- */

.wrapper-kpi {
    width: 33%;
    margin: 0 auto;
    text-align: center;
    margin-top: 2rem;

    .kpi-title {
        color: #fff;
        font-size: 1.4rem;
        font-weight: 200;
    }

    .kpi-icon {
        font-size: 6rem;
        color: $gray-second;
    }
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
    // TODO: move to Button.txt or to specific place
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    margin: 0 7px;

    button {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: $white;

        &:hover {
            opacity: 0.9;
        }
    }
}

.transactions-table thead tr th {
    min-width: auto !important;
}

.transactions {
    .transaction-icon {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }

    &.table {
        .transaction-hash .trim {
            @include media-breakpoint-down(xl) {
                max-width: none;
            }

            @include media-breakpoint-down(lg) {
                max-width: 26rem;
            }

            @include media-breakpoint-down(md) {
                max-width: 12rem;
            }

            @include media-breakpoint-down(sm) {
                max-width: 8rem;
            }
        }

        td,
        tr {
            padding: 0.65rem;
        }

        td {
            white-space: nowrap;
            vertical-align: middle;
        }

        tr:last-of-type {
            td {
                border-bottom: 1px solid #dee2e6;
            }
        }
    }
}

/* Actions
-------------------------------------------------- */

.not-allowed {
    cursor: not-allowed;
}

/* Loader, Transaction Success/Fail
-------------------------------------------------- */
.page-state {
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }

    .lds-ellipsis div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .bg-blue .lds-ellipsis div {
        background: rgba(255, 255, 255, 0.4);
    }

    .lds-ellipsis div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(0);
        }
    }

    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(19px, 0);
        }
    }
}

/* About
-------------------------------------------------- */

.faq-menu-top,
.scroll-top {
    a {
        color: $entity-green;
        text-decoration: none;
    }
}

.accordion-item {
    // TODO: use EAccordion and remove this
    background-color: #3c3d3c;
    border: 1px solid #5a5a5a;
    line-height: 1.7rem;
}

.accordion-button:not(.collapsed) {
    color: $entity-green;
    background-color: #3c3d3c;
    border-color: #5a5a5a;
}

.accordion-button {
    color: $entity-green;
    text-align: left;
    background-color: #3c3d3c;

    &:after {
        filter: invert(74%) sepia(41%) saturate(690%) hue-rotate(61deg) brightness(118%) contrast(58%);
    }
}

h2.title-h2-acord {
    color: #fff;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

/* Footer
-------------------------------------------------- */

.footer-maintenance {
    z-index: 0;
    display: flex;
    background: var(--bg-footer);
    color: $gray-second;
    padding: 0 0 1rem 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 100px;
    margin-top: 12rem;
}

/* Tax Tools */

.txtl-settings {
    $txtl-settings-field-height: calc(40px + 1rem);

    .e-date-range-wrapper,
    .ul-txwallets {
        background: $gray-second;
        border-color: $gray-second !important;
        padding: 1rem;
        min-height: $txtl-settings-field-height;
    }

    .e-date-range-wrapper {
        height: $txtl-settings-field-height;

        .e-input {
            padding: 0 !important;
            font-size: var(--entity-font-size) !important;
        }
    }
}

.wallet-progress {
    min-width: 120px;
    margin: 0.25rem 0 0 0;

    .progress-bar {
        height: 0.2rem;
    }

    &.progress {
        border-radius: 5px !important;
        height: auto;
    }
}

.ul-txwallets {
    list-style-type: none;

    li {
        margin-top: 0.7rem;

        &:nth-child(1) {
            margin-top: 0;
        }
    }

    label {
        margin-left: 0.7rem;
    }
}

ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}

.staking-tab {
    .row {
        .col,
        .col-md-7,
        .col-md-12,
        .col-md-5,
        .col-md-6 {
            padding-left: 8px !important;
            padding-right: 8px !important;
        }
    }
}

.border-blue {
    border: 1px solid #57acfb;
    color: #57acfb;
}

.border-grey {
    border: 1px solid #454444;
}

.title-step {
    svg {
        margin-right: 0.5rem;
    }
}

.table-dark {
    th {
        background: #000000;
        font-size: 0.9rem;
        font-weight: normal;
        padding: 1rem;
    }

    tbody {
        border-top: none !important;
    }

    td {
        font-size: 1rem;
        padding: 0.8rem 1.1rem;
    }

    &:not(caption) {
        background-color: #454444 !important;
    }
}

.progress {
    background-color: $gray-second;
    border-radius: 0;

    .dark-grey-progress {
        background-color: #2e2e2e;
    }

    .grey-progress {
        background-color: $gray-second;
    }

    .light-grey-progress {
        background-color: #708787;
    }

    .green-progress {
        background: $entity-green;
    }

    .white-progress {
        background: #fff;
    }

    .blue-progress {
        background: $medium-blue;
    }
}

.entityTable tbody > tr:nth-child(2n + 1) > td,
.entityTable tbody > tr:nth-child(2n + 1) > th {
    background: #454444;
}

.entityDynamicTable thead > tr > th:last-child {
    visibility: hidden;
}

.entityDynamicTable tbody > tr:nth-child(n) > td:last-child {
    background: #3c3d3c;
}

.table-dark:not(caption) {
    background-color: #3c3d3c !important;
    border-color: #3c3d3c !important;
}

.entityTable > :not(caption) > * > * {
    background: #454444;
}

.entityTable .result-td {
    background-color: #56acff85 !important;
    width: 20%;
}

.entityTable {
    thead {
        tr {
            th {
                background: #333333 !important;
                min-width: 120px;
            }
        }
    }

    tbody {
        tr {
            border-color: #3c3c3c;
        }
    }

    td {
        vertical-align: middle;
    }
}

.info-chart-title {
    font-size: 1.3rem;
}

.relative {
    position: relative;
}

.search-icon {
    position: absolute;
    top: 12px;
    right: 25px;
}

/***  Staking *******/
.hr-strategy {
    height: 0.3rem;
}

.card-header {
    padding: 2rem;
    background: #7e7e7e;
}

.icon-provider {
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.icon-tokens {
    width: 35px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 5px;
}

.icon-provider-sm {
    width: 32px;
    border-radius: 50%;
    margin-right: 10px;
}

.card-footer {
    background: #7e7e7e;
}

.pagination-label {
    color: #ddd;
}

.pagination .page-link {
    background: none !important;
    border: none !important;
    color: #fff;
}

/* Strategie Rewards */

::-webkit-scrollbar {
    border: 0;
    height: 2px;
}

.infoCard {
    min-width: 250px !important;
    margin-left: 0 !important;

    .box-dd,
    .box-dt {
        font-size: 0.88rem;
        text-align: right !important;
    }

    h5 {
        margin-bottom: 31px !important;
    }
}

input.e-control.e-daterangepicker.e-lib.e-input.e-keyboard::placeholder {
    color: var(--field-placeholder-color);
}

.box-rewards-strategies.steps {
    height: 100%;
    background: linear-gradient(180deg, #454444 22%, #5d5d5d 0%);
    border-radius: 1rem;
}

.box-rewards-strategies .card {
    min-width: 170px;
    padding: 0;
    background: #353434 !important;
    border-radius: 0.6rem;
    border: none;
    box-shadow: 5px 5px 11px #0000004d;
    margin-left: 10px;

    .card-header {
        background: #353434;
        padding: 1rem;
        text-align: center;
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;

        h5 {
            font-size: 1rem;
            margin-bottom: 0;
        }
    }

    .card-body {
        padding: 0;

        .box-dd {
            background: #5d5d5d;
        }

        .box-dt {
            background: #545454;
        }

        .box-dx {
            background: #323232;
        }

        .box-title-secondary {
            text-align: center;
            background: #404040;
            padding: 0.5rem 1rem;
            font-size: 0.88rem;
        }

        .box-dd,
        .box-dt,
        .box-dx {
            padding: 0.6rem 1rem;
            height: 35px;
            text-align: center;
        }
    }

    .card-footer {
        background: $gray-second;
        text-align: center;
        padding: 1rem;
        border-bottom-left-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;

        .totalAmount {
            font-size: 1.6rem;
            font-weight: 200;
        }

        .text-info-bottom {
            font-size: 0.85rem;
            color: #d7d7d7;
        }
    }

    .hover-green-slider {
        .box-title-secondary {
            background: #50884f;
        }

        .text-green,
        .box-dt,
        .box-dd {
            color: #333;
        }

        .box-dd {
            background: #86fe94;
        }

        .box-dt {
            background: #72dc6e;
        }

        .box-dx {
            background: #323232;
        }
    }
}

.p-strategy {
    font-size: 0.8rem;
}

.strategy-down-icon {
    font-size: 2rem;
    color: #82f290;
    -webkit-animation: upDown 1s infinite alternate;
    animation: upDown 1s infinite alternate;
}

@-webkit-keyframes upDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-8px);
    }
}

@keyframes upDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-8px);
    }
}

.hr-strategy {
    height: 4px !important;
    background: #181818;
}

/******** Staking ********/

$token-icon-size: 50px;
.token-icon {
    background: #fff;
    padding: 0;
    border-radius: 50%;
    width: $token-icon-size;
    height: $token-icon-size;
    text-align: center;
    box-shadow: 0 5px 10px #00000040;
    color: #333333;

    svg {
        padding: 7px;
        width: $token-icon-size;
        height: $token-icon-size;
    }
}

.wrapper-token {
    background: linear-gradient(90deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 1) 9%, rgba(69, 69, 69, 0.2) 100%);
}

.react-switch-bg > div > svg {
    display: none;
}

.ent-loading {
    text-align: center;
}

.swal2-cancel {
    background-color: #6e7881 !important;
}

.swal2-warning {
    color: #ffa35f !important;
    border-color: #9d5f2d !important;
}

.swal2-error {
    color: #f27474 !important;
    border-color: #c95151 !important;
}

.form-select {
    color: #fff;
    background-color: var(--field-bg-color);
    border: none !important;
    border-radius: 2px;
    padding: 0.7rem 0.75rem;
}

.form-check-input:checked {
    background-color: #000000;
    border-color: #84fc8c;
    color: #84fc8c;
}

.form-control:disabled,
.form-control[readonly] {
    background: #323232;
}

.form-select:disabled,
.form-select[readonly] {
    background: #323232;
}

.homepage-page-title {
    color: #000;
    font-weight: 400 !important;
    font-size: 2.5rem;
}

.title-card-sh {
    color: $gray;
    font-size: 15px;
    margin-bottom: 0.5rem;
}

.ent-shadow {
    box-shadow: 0 0 1rem #00000026 !important;
}

.rdt_Table {
    background-color: #0000 !important;
    border-right: 1px solid #000;
    border-radius: 0.714rem !important;
}

.rdt_TableCell {
    color: #fff;
    font-weight: 300 !important;
    font-size: 1rem !important;
    font-family: var(--entity-font-family) !important;
    padding: 0.5rem 0 0.571rem 0.8rem !important;
    overflow: hidden !important;

    &:nth-child(1) {
        padding-left: 1.5rem !important;
    }
}

.rdt_TableHead {
    z-index: 2 !important;
}

.rdt_TableHeadRow {
    background: #305c5d !important;
    color: #fff;
    font-weight: bolder !important;
    border-top-left-radius: 0.714rem;
    border-top-right-radius: 0.714rem;

    .rdt_TableCol:first-child {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 3;
        background-color: #305c5d;
    }

    &:nth-child(1) {
        padding-left: 1rem !important;
    }

    .rdt_TableCol {
        font-size: 1rem !important;
        font-family: var(--primary-text) !important;
        padding-left: 0.8rem !important;
        padding-right: 0 !important;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.rdt_TableBody .rdt_TableRow {
    &:nth-child(even) {
        background-color: #1e4141;
    }

    &:nth-child(odd) {
        background-color: #153539;
    }

    .rdt_TableCell:first-child {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: #122a2a;

        @media (min-width: 1200px) {
            position: inherit;
            z-index: 0;
            background-color: inherit;
        }
    }
}

.rdt_Pagination {
    background-color: #000 !important;
    padding: 1.5rem;
    color: #fff !important;
    border-bottom-left-radius: 0.714rem;
    border-bottom-right-radius: 0.714rem;

    #pagination-first-page,
    #pagination-previous-page,
    #pagination-next-page,
    #pagination-last-page {
        fill: #fff;
    }

    #pagination-first-page:disabled,
    #pagination-previous-page:disabled,
    #pagination-next-page:disabled,
    #pagination-last-page:disabled {
        color: #fff;
        fill: #fff;
    }
}

/* Taxtool Maiar Exchange */

.icon-video {
    position: relative;
    left: 3px;
}

.maiarexchange-tab {
    .icon-tx-card.maiar-tab {
        display: block;
        width: 35px;
        height: 35px;
        text-align: center;
        border-radius: 2.5rem;
        padding: 0.3rem;
        border: 2px solid #3c3c3c;
        background: #454444;
        margin: 0 auto;
        position: absolute;
        left: 49%;
        top: -1rem;
    }

    .dashed-text {
        border-bottom: 1px solid rgb(126, 126, 126);
        text-align: center;
        color: rgb(126, 126, 126);
        font-size: 0.9rem;
        height: 10px;
        margin-bottom: 10px;
    }

    .dashed-text div {
        background: rgb(52, 51, 51);
        padding: 0 5px;
        max-width: 160px;
        display: block;
        margin: 0 auto;
    }
}

.mxt-symbol {
    background: #2b2b2b;
    border-radius: 50%;
    width: 39px;
    height: 39px;
    display: block;
    margin-right: 0.5rem;
    text-align: center;

    img {
        max-height: 30px;
        filter: grayscale(1);
        margin-top: 4px;
        padding: 1px;
        max-width: 30px;
    }
}

/* Maiar Exchange */

.title-mx-card {
    font-size: 1.4rem;
}

.mx-card {
    max-width: 170px;
}

.sidebar-mx {
    background: #454545 0 0 no-repeat padding-box;
    box-shadow: 3px 3px 10px #00000029;
}

.mx-lp-activity {
    font-size: 0.9rem;
    color: $light-gray;

    .row {
        display: table-row;
    }

    .col-md-2,
    .col-md-10 {
        display: table-cell;
        vertical-align: middle;
    }
}

.token-mx-symbol {
    display: block;
    margin-right: 0.5rem;

    img {
        max-height: 18px;
    }

    .row {
        display: table-row;
    }

    .col-md-2,
    .col-md-10 {
        display: table-cell;
        vertical-align: middle;
    }
}

.modal-container .modal-dialog .modal-card .card {
    background: none !important;
}

.e-modal-opaque {
    background-color: var(--green-opaque) !important;
}

.card-title-mx {
    background: none;
    padding: 1rem 2rem;
}

.total-mx-liquidity {
    font-size: 1.9rem;
}

.box-red {
    background: #ca6f6f;
    padding: 0.3rem 0.6rem;
    border-radius: 3px;
    color: #fff;
}

.date-mx-liquidity {
    color: $gray;
}

/* Maintenance */

.homepage.maintenance {
    background-image: url("../img/maintenance.jpg");
    background-size: cover !important;
    background-position: top !important;
    background-position-y: 0 !important;
}

.header-maintenance {
    &:before {
        content: "";
        position: absolute;
        top: -80px;
        left: 0;
        height: 144px;
        width: 50%;
        background: var(--bg-header);
        transform: skew(0deg, 6deg);
    }

    &:after {
        content: "";
        position: absolute;
        top: -80px;
        right: 0;
        height: 144px;
        width: 50%;
        background: var(--bg-header);
        transform: skew(0deg, -6deg);
    }
}

.maintenance {
    footer,
    footer:after,
    footer:before {
        background: #333333;
    }

    .logo-maintenance {
        filter: invert(100%) sepia(100%) contrast(200%);
        margin-bottom: 2rem;
    }

    .social {
        margin-top: 1rem;

        a {
            color: #000 !important;
            font-size: 1.3rem;
        }
    }

    .logo-footer {
        max-height: 45px;
    }
}

.delegation-metrics-chart {
    max-height: 400px;
}

.z-1 {
    z-index: 1;
}

/** ICO **/

.ico-logo-list {
    width: 40px;
    border-radius: 50%;
    margin-right: 15px;
}

.ico-logo-successful-launches {
    border-radius: 50%;
}

.icoDetails-title {
    color: $light-gray;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.icoDetails p {
    line-height: 1.25rem;

    .info {
        width: 210px;
        display: inline-flex;
    }
}

.logo-ico {
    width: 100%;
    padding: 1.7rem;
    border: 1px solid #d3dfd4;
    background: #3c3c3c;
    border-radius: 0.7rem;
    box-shadow: 0 0 123px #84fc8c96;
}

.listing-wrapper-ico {
    border-radius: 0.7rem;
    border: 2px solid #00000029;
    padding: 1rem 1.4rem 1rem 2.3rem;
    max-height: 80px;
    background: var(--card-bg);
    backdrop-filter: blur(0.7rem);
    -webkit-backdrop-filter: blur(0.7rem);
    -moz-backdrop-filter: blur(0.7rem);

    .ico-name {
        color: #fff;
        font-size: 1.1rem;
        font-weight: bold;
        min-width: 130px;
    }

    .img-listing-ico {
        max-height: 75px;
        padding: 1rem;
    }

    .ico-date {
        color: #84fc8c;
    }
}

.steptwo .e-date-wrapper,
.adminForm .e-date-wrapper {
    border-radius: 3px;
    padding: 0.4rem 0.75rem;
}

.adminForm {
    label {
        margin-top: 1.5rem;
    }

    .date-quarter-switcher-container {
        margin-top: 1.5rem;
    }
}

span.e-input-group.e-control-wrapper {
    margin-bottom: 0;
}

span.e-input-group.e-control-wrapper.e-date-wrapper {
    color: white;
    background: $gray-second;

    > input {
        padding-left: 0.75rem !important;
    }

    > span.e-clear-icon {
        color: white;
    }

    > span.e-input-group-icon.e-date-icon.e-icons {
        color: white;
    }
}

.social-ico {
    .nav-link {
        color: $gray;
    }
}

.social-ico-table {
    .nav-link {
        color: #fff;
        padding: 0.5rem 0.3rem;

        &:hover {
            color: #84fc8c;
        }
    }
}

.entity-card-rating {
    .number {
        font-size: 2.2rem;
        font-weight: bold;
        color: #fff;
    }
}

.img-listing-ico-table {
    max-height: 50px;
}

.ico-picture {
    height: 13.3rem;
    width: 13.3rem;
    border-radius: 8.5rem;
    position: relative;
    display: block;
    border: 7px solid #787878;

    .picture {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 50%;
            box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.33);
            -webkit-box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.33);
            -moz-box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.33);
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            border-radius: 50%;
        }
    }
}

.icopage-description {
    color: #fff;
}

.ico-moto {
    color: #b5e1df;
    text-transform: uppercase;
    margin-top: 1.8rem;
    font-size: 1.5rem;
}

.e-upload-container {
    margin-bottom: 0.8rem;
}

.e-upload-container .drag-and-drop-area {
    border: 0.13rem dashed $gray-second;
    border-radius: 1.5rem;
    padding: 1rem;
    text-align: center;
    color: #ccc;
    cursor: pointer;

    .e-upload-name {
        margin: 0 0.5rem;
        border: 0.1rem solid #606060;
        padding: 0 0.5rem;
    }
}

/* NFT Minting */

.nft-features {
    border: 4px solid rgb(91, 91, 91);
    border-radius: 4px;
    box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.33);
    -webkit-box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 1px 4px 24px 0 rgba(0, 0, 0, 0.33);
}

.nft-progressbar {
    height: 10px;
    color: #000;
}

.nft-important-info {
    background-color: #333333;
    border-radius: 10px;
    padding: 2.2rem 1.3rem;

    .text-warning {
        color: #ffec83 !important;
    }
}

.slick-slide {
    padding: 1rem;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
    color: white;
    font-size: 10px;
    opacity: 1 !important;
}

.slick-dots li button:before {
    opacity: 0.35 !important;
}

.nftwallet-wrapper {
    padding: 1rem;
    background: #454444;
    border: 1px solid #606060;
    border-radius: 10px;
}

.slick-track {
    margin-left: 0;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/******** Portfolio ***********/

.portfolio-block {
    &.first-widget {
        min-height: 88px;
    }

    &.second-widget {
        min-height: 132px;
    }

    .green-progress {
        background-color: $entity-green;
    }

    .blue-progress {
        background-color: rgba(99, 168, 232, 1);
    }

    .green-dark-progress {
        background-color: rgb(62, 212, 28);
    }

    .blue-dark-progress {
        background-color: rgb(54, 139, 219);
    }
}

.portfolio-block-info {
    padding: 3px 10px;
    text-align: center;

    .total {
        font-size: 2rem;
    }

    .total-text {
        color: #a3b1b4;
    }
}

.token-social a {
    color: #ccc;
    font-size: 15px;
    padding: 10px;

    :hover {
        color: #83ff8c !important;
    }
}

.free-text-controller {
    > div.editor-wrapper.form-control.rdw-editor-wrapper > div.editor.rdw-editor-main {
        max-height: 15rem;
    }
}

.mt-35 {
    margin-top: 35px;
}

.mw-20{
    max-width: 20px;
}

.small-text{
    font-size: 10px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.info-icon {
    color: #949494;
}

.text-secondary {
    color: #a3b1b4 !important;
}

.swal2-height-auto {
    padding: 0 !important;
}

.swal2-popup.swal2-modal {
    border-color: #00000061;
    border-style: inset;
    border-radius: 10px;
    border-width: 2px;
}

.min-h-5 {
    min-height: 5rem !important;
}