@import "./variables.scss";

$dp-primary-color: #46a34d;

.e-input-group.e-control-wrapper.e-datetime-wrapper,
.e-input-group.e-control-wrapper.e-date-wrapper,
.e-input-group.e-control-wrapper.e-date-range-wrapper {
    background: var(--field-bg-color) !important;
    padding: 5px;
    border: none;

    input {
        color: white !important;

        &::placeholder {
            color: var(--field-placeholder-color) !important;
        }
    }

    .e-icons {
        color: #708787 !important;
    }

    &.e-disabled {
        // css const - ${disabledField};
        background-color: var(--disabled-field-bg-color) !important;
        color: var(--disabled-field-color) !important;
        cursor: not-allowed !important;
        -webkit-text-fill-color: var(--disabled-field-color) !important;

        input:disabled {
            color: inherit !important;
            -webkit-text-fill-color: inherit !important;

            &::placeholder {
                color: inherit !important;
                -webkit-text-fill-color: inherit !important;
            }
        }
    }
}

.e-daterangepicker,
.e-datetime-wrapper,
.e-date-wrapper,
.e-date-range-wrapper {
    font-family: $entity-font-family;
}

.e-input.e-error,
.e-input.e-error:focus,
.e-input-group.e-error input.e-input,
.e-input-group.e-control-wrapper.e-error input.e-input,
.e-input-group.e-control-wrapper.e-error .e-input-group-icon,
.e-input-group.e-error .e-input-group-icon,
.e-input-group.e-error textarea.e-input,
.e-input-group.e-control-wrapper.e-error textarea.e-input,
.e-input-group.e-error,
.e-input-group.e-control-wrapper.e-error,
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    border-bottom-color: $dp-primary-color;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day,
.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day {
    color: #000;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-today:hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-start-date.e-selected.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-end-date.e-selected.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-today:hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-start-date.e-selected.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-end-date.e-selected.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-selected.e-today:hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-selected.e-today:hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day {
    border: 1px solid $dp-primary-color;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-css.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary:hover,
.e-calendar .e-content .e-footer,
.e-bigger.e-small .e-calendar .e-content .e-footer,
.e-time-wrapper .e-input-group-icon.e-icons.e-active,
.e-time-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active,
.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active,
.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover,
.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active,
.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active,
.e-date-range-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active,
.e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span,
.e-daterangepicker.e-popup .e-presets .e-list-item.e-active,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-item.e-active,
.e-datetime-wrapper .e-input-group-icon.e-icons.e-active,
.e-datetime-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
    // .e-apply
    color: $dp-primary-color;
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day,
.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day,
.e-datepicker .e-model-header,
.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
    background: $dp-primary-color;
    color: white;
}

.e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span {
    background-color: #eee;
    color: $dp-primary-color;
}

span.e-input-group.e-control-wrapper.e-datetime-wrapper,
span.e-input-group.e-control-wrapper.e-date-wrapper,
span.e-input-group.e-control-wrapper.e-date-range-wrapper {
    margin-bottom: 0;
    color: #fff;
}

input#ej2-datetimepicker_0::placeholder {
    color: var(--disabled-field-color) !important;
    padding-left: 0.5rem;
}

span.e-input-group.e-control-wrapper.e-disabled.e-date-wrapper.e-valid-input.e-non-edit:nth-last-child(1) {
    -webkit-text-fill-color: var(--disabled-field-color) !important;
}

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
    background: $gray !important;
}

.e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
    border-color: $entity-red;
}

input.e-control.e-daterangepicker.e-lib.e-input.e-keyboard::placeholder {
    color: var(--disabled-field-color) !important;
}
