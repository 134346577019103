.editor-wrapper {
  font-size: 14px;
  font-family: "Krub", sans-serif;
  box-sizing: border-box;
  color: inherit;

  .toolbar {
    display: none;
    color: black;
  }

  &:focus-within {
    background: var(--dark-green);
    color: #fff;

    .toolbar {
      display: flex;
    }
  }

  a, a:hover {
    color: inherit;
  }
}