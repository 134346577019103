.unlockModal {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 992px) {
    height: 30vh;
  }

  .wrapper {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 30px 30px;
    padding: 1rem 1.4rem 1rem;
    z-index: 1;

    .login_wrapper {
      border: 1px solid #ccc;
    }

    .heading {
      line-height: 1;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
    }

    .hr {
      height: 1px;
      margin: 2rem;
      background: #fff;
      width: 100%;
    }

    .description {
      font-size: 1rem;
      color: #b9b9b9;
      text-align: center;

      svg {
        color: #83FF8C;
      }
    }

    .textinfo {
      color: #fff;
      font-size: 1rem;
      border-top: 1px solid #5e5e5e;
      border-bottom: 1px solid #5e5e5e;
      width: 100%;
      text-align: center;
      margin-top: 1.4rem;
      padding: 1.5rem;
    }

    .connects {
      display: flex;
      margin-top: 45px;
      flex-wrap: wrap;
      justify-content: center;

      button,
      a {
        border: 1px solid #818181;
        background: none;
        padding: 0 !important;
        margin: 0.6rem!important;

        &:hover:enabled, &:active:enabled, &:focus {
          background: #346c6d !important;
          border: 1px solid #85fc8c !important;
        }
      }

      [disabled] {
        opacity: .6;
        cursor: default;
      }

      .connect {
        border-radius: 6px;
        width: 180px;
        padding: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;

        .title {
          color: #fff;
          margin-bottom: 15px;
          font-size: 12px;
          line-height: 1;
        }

        .icon {
          border-radius: 16px;
          width: 60px;
          height: 60px;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            max-width: 30px;
            max-height: 30px;
          }
        }

        .name {
          color: #fff;
          font-weight: 600;
          font-size: 11px;
        }
      }
    }
  }
}

.unlock {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 992px) {
    height: 60vh;
  }

  .wrapper {
    background: var(--card-bg);
    backdrop-filter: blur(0.7rem);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 30px 30px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.09);
    padding: 3rem 1.4rem 1rem;
    z-index: 1;

    .login_wrapper {
      border: 1px solid #ccc;
    }

    .heading {
      line-height: 1;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .hr {
      height: 1px;
      margin: 2rem;
      background: #fff;
      width: 100%;
    }

    .description {
      font-size: 1rem;
      color: #b9b9b9;
      text-align: center;

      svg {
        color: #83FF8C;
      }
    }

    .textinfo {
      color: #fff;
      font-size: 1rem;
      border-top: 1px solid #5e5e5e;
      border-bottom: 1px solid #5e5e5e;
      width: 100%;
      text-align: center;
      margin-top: 2.4rem;
      padding: 1.5rem;
    }

    .connects {
      display: flex;
      margin-top: 45px;
      flex-wrap: wrap;
      justify-content: center;

      button,
      a {
        border: 1px solid #818181;
        background: none;
        padding: 0 !important;
        margin: 0.6rem!important;

        &:hover, &:active, &:focus {
          background: #346c6d !important;
          border: 1px solid #85fc8c !important;
        }
      }

      .connect {
        cursor: pointer;
        border-radius: 6px;
        width: 180px;
        padding: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;

        .title {
          color: #fff;
          margin-bottom: 15px;
          font-size: 12px;
          line-height: 1;
        }

        .icon {
          border-radius: 16px;
          width: 60px;
          height: 60px;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            max-width: 30px;
            max-height: 30px;
          }
        }

        .name {
          color: #fff;
          font-weight: 600;
          font-size: 11px;
        }
      }
    }
  }
}

.qr {
  border-radius: 12px;
  padding: 30px;
  background: rgba(36, 37, 38, 1);
  position: relative;

  .close {
    position: absolute;
    bottom: calc(100% + 5px);
    left: calc(100% + 5px);
    border-radius: 50%;
    background: rgba(36, 37, 38, 1);
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 15px;
      height: 15px;

      path {
        fill: white;
      }
    }
  }

  .code {
    margin-bottom: 30px;

    svg {
      border-radius: 12px;
    }
  }

  .subheading {
    line-height: 1;
    color: white;
    font-size: 20px;
    text-align: center;
  }
}
